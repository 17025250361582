import { forwardRef } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook, faInstagram, faXTwitter, faLinkedin, faWhatsapp } from "@fortawesome/free-brands-svg-icons"
import { faEnvelope, faPhone, faAngleDown } from "@fortawesome/free-solid-svg-icons"

// Functional Component Info
const Info = forwardRef(({ props: country }, ref) => {
  // Destructuring properties from the country object
  const { id, name, url, contactUs, knowUs, color } = country
  const { name: contactUsName, values: contactUsValues } = contactUs
  // Destructuring the knowUs property with a default value
  const { name: knowUsName, values: knowUsValues } = knowUs ?? { name: '', values: [] }

  // Imported Font Awesome icons
  const icons = {
    socialMedia: {
      faFacebook, faInstagram, faXTwitter, faLinkedin
    },
    contact: {
      faEnvelope, faPhone, faWhatsapp
    }
  }

  return (
    <>
      {/* Country Information Section */}
      <section ref={ref} className="countryinfo-section countryinfo-section-md countryinfo-section-xl max-[1280px]:visible max-[1280px]:pointer-events-auto">
        {/* Checkbox to toggle information visibility */}
        <input type="checkbox" name={id} id={id} className="country-checkbox country-checkbox-xl" />
        <h2>
          {/* Country label with down arrow icon */}
          <label htmlFor={id}>
            <span className={`countryinfo-label-span countryinfo-label-span-md countryinfo-label-span-xl ${color.background} ${color.text}`}>
              <span className='grid place-items-center text-center'>
                {name}
              </span>
              <span>
                <FontAwesomeIcon icon={faAngleDown} size="xs" />
              </span>
            </span>
          </label>
        </h2>
        <div className="wrapper wrapper-xl">
          <div className="inner inner-md inner-xl">
            {/* Link to the country's website */}
            <a className="countryinfo-url countryinfo-url-md countryinfo-url-xl" aria-label={`Go to ${name} website`} href={`https://${url}`} target="_blank" rel="noreferrer" tabIndex={-1}>
              {url}
            </a>
            <div className="countryinfo-div countryinfo-div-md countryinfo-div-xl">
              <ul className="countryinfo-ul countryinfo-ul-md countryinfo-ul-xl">
                {/* Contact Information Section */}
                <li className="countryinfo-contact-us countryinfo-contact-us-md countryinfo-contact-us-xl">
                  <h2>
                    {contactUsName}
                  </h2>
                  <nav>
                    <ul className="countryinfo-contact-us-sub-ul countryinfo-contact-us-sub-ul-md countryinfo-contact-us-sub-ul-xl">
                      {/* List of contact details */}
                      {contactUsValues.map(({ contact, icon, url }, i) => (
                        <li key={i}>
                          {/* Font Awesome contact icon */}
                          <FontAwesomeIcon icon={icons.contact[icon]} className={`${color.icon}`} />
                          {/* Link to the contact detail */}
                          <a href={url} aria-label='Contact link' target="_blank" rel="noreferrer" tabIndex={-1}>
                            {contact}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </li>
                {/* Know Us Section */}
                <li className="countryinfo-know-us countryinfo-know-us-md countryinfo-know-us-xl">
                  <h2>
                    {knowUsName}
                  </h2>
                  <nav>
                    <ul className="countryinfo-know-us-sub-ul countryinfo-know-us-sub-ul-md countryinfo-know-us-sub-ul-xl">
                      {/* List of social media icons */}
                      {knowUsValues.map(({ icon, url }, i) => (
                        <li key={i}>
                          {/* Font Awesome social media icon */}
                          <a href={url} aria-label='Social media link' target="_blank" rel="noreferrer" tabIndex={-1}>
                            <FontAwesomeIcon icon={icons.socialMedia[icon]} className={`${color.icon}`} />
                          </a>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  )
})

export default Info
