// Import the Logo component from a relative path and the Link component from 'react-router-dom'
import Logo from '../../logo-enveseur.svg'
import { Link } from 'react-router-dom'

// Define the PrivacyPolicy component
const PrivacyPolicy = () => {
    // Function to get the current year
    const getCurrentYear = () => new Date().getFullYear()

    return (
        <>
            {/* Container for the cookie policy page */}
            <div className='cp-lp-container'>
                {/* Header section */}
                <header className='cp-lp-header'>
                    {/* Navigation */}
                    <nav>
                        <ul>
                            <li>
                                {/* Link to the homepage */}
                                <Link to='/'>
                                    <img width="400" height="200" src={Logo} alt="Enveseur" />
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </header>
                {/* Main content section */}
                <main className='cp-lp-main'>
                    {/* Privacy policy section */}
                    <section className='cp-lp-section'>
                        <h1 className='cp-lp-h1'>
                            Privacy policy
                        </h1>
                    </section>
                    {/* About cookies section */}
                    <section className='cp-lp-section'>
                        <h2 className='cp-lp-h2'>
                            Consent to the use of cookies.
                        </h2>
                        <p>For our website to function properly we use cookies. To obtain your valid consent for the use and storage of cookies in the browser you use to access our website and to properly document this we use a consent management platform: CookieFirst. This technology is provided by Digital Data Solutions BV, Plantage Middenlaan 42a, 1018 DH, Amsterdam, The Netherlands. Website: <a href='https://cookiefirst.com' title='Cookiefirst page'>https://cookiefirst.com</a> referred to as CookieFirst.</p>
                        <p>When you access our website, a connection is established with CookieFirst’s server to give us the possibility to obtain valid consent from you to the use of certain cookies. CookieFirst then stores a cookie in your browser in order to be able to activate only those cookies to which you have consented and to properly document this. The data processed is stored until the predefined storage period expires or you request to delete the data. Certain mandatory legal storage periods may apply notwithstanding the aforementioned.</p>
                        <p>CookieFirst is used to obtain the legally required consent for the use of cookies. The legal basis for this is article 6(1)(c) of the General Data Protection Regulation (GDPR).</p>
                        <h2>Data processing agreement</h2>
                        <p>We have concluded a data processing agreement with CookieFirst. This is a contract required by data protection law, which ensures that data of our website visitors is only processed in accordance with our instructions and in compliance with the GDPR.</p>
                        <h2>Server log files</h2>
                        <p>Our website and CookieFirst automatically collect and store information in so-called server log files, which your browser automatically transmits to us. The following data is collected:</p>
                        <ul>
                        <li>Your consent status or the withdrawal of consent</li>
                        <li>Your anonymised IP address</li>
                        <li>Information about your Browser</li>
                        <li>Information about your Device</li>
                        <li>The date and time you have visited our website</li>
                        <li>The webpage url where you saved or updated your consent preferences</li>
                        <li>The approximate location of the user that saved their consent preference</li>
                        <li>A universally unique identifier (UUID) of the website visitor that clicked the cookie banner</li>
                        </ul>
                    </section>
                    {/* Contact information section */}
                    <section className='cp-lp-section'>
                        <h2 className='cp-lp-h2'>
                            Contact us
                        </h2>
                        <p>
                            {/* Information about the website's ownership */}
                            This website is owned and operated by Enveseur S.L.
                        </p>
                        <p>
                            {/* Contact information */}
                            If you have any questions about this privacy policy, please contact us by email to <a href="mailto:sales@enveseur.com">sales@enveseur.com</a>, by telephone to  <a href="tel:+34911875521">+34 911 87 55 21</a> or by post.
                        </p>
                    </section>
                </main>
            </div>
            {/* Footer section */}
            <footer className='cp-lp-footer footer-bg'>
                <div className='cp-lp-footer-div'>
                    <div className='cp-lp-footer-div-div'>
                        {/* Footer links */}
                        <ul className='cp-lp-footer-ul-first'>
                            <li>
                                {/* Copyright information */}
                                Copyright © {getCurrentYear()} Enveseur SL
                            </li>
                            <li>
                                {/* Company address */}
                                <address>Carrer Avinyonet, 6<br />Vilafranca del Penedès, 08720<br />Barcelona, España<br />B66856618</address>
                            </li>
                        </ul>
                        <ul className='cp-lp-footer-ul'>
                            <li>
                                {/* Contact email */}
                                <a href="mailto:sales@enveseur.com">
                                    sales@enveseur.com
                                </a>
                            </li>
                            <li>
                                {/* Contact telephone */}
                                <a href="tel:+34935220222">
                                    +34 935 22 02 22
                                </a>
                            </li>
                        </ul>
                        <ul className='cp-lp-footer-ul'>
                            <li>
                                {/* Link to the Cookie Policy page */}
                                <Link to='/cookie-policy'>
                                    Cookie policy
                                </Link>
                            </li>
                            <li>
                                {/* Link to the Privacy Policy page */}
                                <Link to='/privacy-policy'>
                                    Privacy policy
                                </Link>
                            </li>
                            <li>
                                {/* Link to the Legal Note page */}
                                <Link to='/legal-note'>
                                    Legal note
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
        </>
    )
}

// Export the PrivacyPolicy component as the default export
export default PrivacyPolicy
