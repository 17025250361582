import EuropeMap from '../../components/europeMap'
import CountriesInfo from '../../components/countriesInfo/desktop'
import CountriesInfoMobile from '../../components/countriesInfo/mobile'
import { useRef, useState, useEffect } from 'react'
import Logo from '../../../src/logo-enveseur.svg'
import { Link } from 'react-router-dom'
import { isMobile } from 'react-device-detect'

const Home = () => {
    // Function to get the current year
    const getCurrentYear = () => new Date().getFullYear()

    const [defaultMainText, setDefaultMainText] = useState('')
    const [mainText, setMainText] = useState('')

    // References to DOM elements
    const countriesInfoElementsRef = useRef({})
    const mainTitlesSectionRef = useRef({})

    useEffect(() => {
        const DEFAULT_MAIN_TEXT = 'We sell all over Europe!'
        setDefaultMainText(DEFAULT_MAIN_TEXT)
        setMainText(DEFAULT_MAIN_TEXT)
    }, [])

    // Function to refresh the page
    const refreshPage = () => window.location.reload(false)

    return (isMobile ?  
        <>
            {/* If the page is opened with a mobile device, the map section will not load. */}
            <div className="home-parent-container">
                <div className="home-left-container home-left-container-md">
                    <header className="home-header">
                        <figure className="home-logo">
                            {/* Logo that reloads the page on click */}
                            <img onClick={refreshPage} className="home-img home-img-md" width="400" height="200" src={Logo} alt="Enveseur" />
                        </figure>
                    </header>
                    <section ref={mainTitlesSectionRef} className="home-section">
                        <div className="home-section-container">
                            <h1 className="home-main-title-static home-main-title-static-md">
                                {defaultMainText}
                            </h1>
                            <h2 className="home-main-title-dynamic home-main-title-dynamic-md">
                                {mainText}
                            </h2>
                        </div>
                    </section>
                    <main className="home-main home-main-md gap-2">
                        <CountriesInfoMobile/>
                    </main>
                </div>
            </div>
            <footer className='home-footer home-footer-md'>
                <div className='home-footer-div'>
                    <div className='home-footer-div-div'>
                        <ul className='home-footer-ul-first'>
                            <li>
                                {/* Current year in footer */}
                                Copyright © {getCurrentYear()} Enveseur SL
                            </li>
                            <li>
                                <address className='home-footer-address'>
                                    <span>Carrer Avinyonet, 6</span>
                                    <span>Vilafranca del Penedès, 08720</span>
                                    <span>Barcelona, Spain</span>
                                    <span>B66856618</span>
                                </address>
                            </li>
                        </ul>
                        <ul className='home-footer-ul'>
                            <li>
                                {/* Email link */}
                                <a href="mailto:sales@enveseur.com">
                                    sales@enveseur.com
                                </a>
                            </li>
                            <li>
                                {/* Phone number link */}
                                <a href="tel:+34935220222">
                                    +34 935 220 222
                                </a>
                            </li>
                        </ul>
                        <ul className='home-footer-ul home-footer-ul-xl'>
                            <li>
                                {/* Cookie policy link */}
                                <Link to='/cookie-policy'>
                                    Cookie policy
                                </Link>
                            </li>
                            <li>
                                {/* Legal note link */}
                                <Link to='/legal-note'>
                                    Legal note
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
        </> 
        : 
        <>
            <div className="home-parent-container home-parent-container-xl">
                <div className="home-left-container home-left-container-md home-left-container-xl">
                    <header className="home-header home-header-xl">
                        <figure className="home-logo home-logo-xl">
                            {/* Logo that reloads the page on click */}
                            <img onClick={refreshPage} className="home-img home-img-md home-img-xl" width="400" height="200" src={Logo} alt="Enveseur" />
                        </figure>
                    </header>
                    <section ref={mainTitlesSectionRef} className="home-section home-section-xl">
                        <div className="home-section-container home-section-container-xl">
                            <h1 className="home-main-title-static home-main-title-static-md home-main-title-static-xl">
                                {defaultMainText}
                            </h1>
                            <h2 className="home-main-title-dynamic home-main-title-dynamic-md home-main-title-dynamic-xl">
                                {mainText}
                            </h2>
                            <div className="home-section-p-container home-section-p-container-xl">
                                <p className="home-p home-p-xl">
                                    Check your country's website by
                                    <br />
                                    clicking on the map
                                </p>
                            </div>
                        </div>
                    </section>
                    <main className="home-main home-main-md home-main-xl">
                        <CountriesInfo ref={countriesInfoElementsRef} />
                    </main>
                </div>
                <aside className="home-right-container home-right-container-xl">
                    {/* EuropeMap component with references */}
                    <EuropeMap setMainText={setMainText} defaultMainText={defaultMainText} ref={{ countriesInfoElementsRef, mainTitlesSectionRef }} />
                </aside>
            </div>
            <footer className='home-footer home-footer-md home-footer-xl'>
                <div className='home-footer-div home-footer-div-xl'>
                    <div className='home-footer-div-div home-footer-div-div-xl'>
                        <ul className='home-footer-ul-first home-footer-ul-xl'>
                            <li>
                                {/* Current year in footer */}
                                Copyright © {getCurrentYear()} Enveseur SL
                            </li>
                            <li>
                                <address className='home-footer-address home-footer-address-xl'>
                                    <span>Carrer Avinyonet, 6</span>
                                    <span>Vilafranca del Penedès, 08720</span>
                                    <span>Barcelona, Spain</span>
                                    <span>B66856618</span>
                                </address>
                            </li>
                        </ul>
                        <ul className='home-footer-ul home-footer-ul-xl'>
                            <li>
                                {/* Email link */}
                                <a href="mailto:sales@enveseur.com">
                                    sales@enveseur.com
                                </a>
                            </li>
                            <li>
                                {/* Phone number link */}
                                <a href="tel:+34935220222">
                                    +34 935 220 222
                                </a>
                            </li>
                        </ul>
                        <ul className='home-footer-ul home-footer-ul-xl'>
                            <li>
                                {/* Cookie policy link */}
                                <Link to='/cookie-policy'>
                                    Cookie policy
                                </Link>
                            </li>
                            <li>
                                {/* Privacy policy link */}
                                <Link to='/cookie-policy'>
                                    Privacy policy
                                </Link>
                            </li>
                            <li>
                                {/* Legal note link */}
                                <Link to='/legal-note'>
                                    Legal note
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Home
