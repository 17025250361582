// Import the Logo component from a relative path and the Link component from 'react-router-dom'
import Logo from '../../logo-enveseur.svg'
import { Link } from 'react-router-dom'

// Define the CookiePolicy component
const CookiePolicy = () => {
    // Function to get the current year
    const getCurrentYear = () => new Date().getFullYear()

    return (
        <>
            {/* Container for the cookie policy page */}
            <div className='cp-lp-container'>
                {/* Header section */}
                <header className='cp-lp-header'>
                    {/* Navigation */}
                    <nav>
                        <ul>
                            <li>
                                {/* Link to the homepage */}
                                <Link to='/'>
                                    <img width="400" height="200" src={Logo} alt="Enveseur" />
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </header>
                {/* Main content section */}
                <main className='cp-lp-main'>
                    {/* Cookie policy section */}
                    <section className='cp-lp-section'>
                        <h1 className='cp-lp-h1'>
                            Cookie policy
                        </h1>
                        <p>
                            {/* Description of the cookie policy */}
                            Our website uses cookies. By using our website and agreeing to this policy, you consent to our use of cookies in accordance with the terms of this policy.
                        </p>
                    </section>
                    {/* About cookies section */}
                    <section className='cp-lp-section'>
                        <h2 className='cp-lp-h2'>
                            About cookies
                        </h2>
                        <p>
                            <div id="cookiefirst-policy-page"></div>
                            <div>This cookie policy has been created and updated by <a href="https://cookiefirst.com/consent-management-platform/">Consent Management - CookieFirst</a>.</div>

                            <div id="cookiefirst-cookies-table"></div>
                            <div>This cookie table has been created and updated by the <a href="https://cookiefirst.com/cookie-blocker/">CookieFirst - Cookie Blocker</a>.</div>
                        </p>
                    </section>
                    {/* Contact information section */}
                    <section className='cp-lp-section'>
                        <h2 className='cp-lp-h2'>
                            Contact us
                        </h2>
                        <p>
                            {/* Information about the website's ownership */}
                            This website is owned and operated by Enveseur S.L.
                        </p>
                        <p>
                            {/* Contact information */}
                            If you have any questions about our cookies or this cookies policy, please contact us by email to <a href="mailto:sales@enveseur.com">sales@enveseur.com</a>, by telephone to  <a href="tel:+34911875521">+34 911 87 55 21</a> or by post.
                        </p>
                    </section>
                </main>
            </div>
            {/* Footer section */}
            <footer className='cp-lp-footer footer-bg'>
                <div className='cp-lp-footer-div'>
                    <div className='cp-lp-footer-div-div'>
                        {/* Footer links */}
                        <ul className='cp-lp-footer-ul-first'>
                            <li>
                                {/* Copyright information */}
                                Copyright © {getCurrentYear()} Enveseur SL
                            </li>
                            <li>
                                {/* Company address */}
                                <address>Carrer Avinyonet, 6<br />Vilafranca del Penedès, 08720<br />Barcelona, España<br />B66856618</address>
                            </li>
                        </ul>
                        <ul className='cp-lp-footer-ul'>
                            <li>
                                {/* Contact email */}
                                <a href="mailto:sales@enveseur.com">
                                    sales@enveseur.com
                                </a>
                            </li>
                            <li>
                                {/* Contact telephone */}
                                <a href="tel:+34935220222">
                                    +34 935 22 02 22
                                </a>
                            </li>
                        </ul>
                        <ul className='cp-lp-footer-ul'>
                            <li>
                                {/* Link to the Cookie Policy page */}
                                <Link to='/cookie-policy'>
                                    Cookie policy
                                </Link>
                            </li>
                            <li>
                                {/* Link to the Privacy Policy page */}
                                <Link to='/privacy-policy'>
                                    Privacy policy
                                </Link>
                            </li>
                            <li>
                                {/* Link to the Legal Note page */}
                                <Link to='/legal-note'>
                                    Legal note
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
        </>
    )
}

// Export the CookiePolicy component as the default export
export default CookiePolicy
