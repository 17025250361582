// Import necessary components from 'react-router-dom'
import { BrowserRouter, Route, Routes } from "react-router-dom"

// Import the components representing different pages
import EnveseurApp from "./pages/home"
import CookiePolicy from "./pages/cookiePolicy"
import PrivacyPolicy from "./pages/privacyPolicy"
import LegalNote from "./pages/legalNote"
import Page404 from "./pages/404"

// Define the main App component
const App = () => (
    <>
        {/* Use BrowserRouter to enable routing */}
        <BrowserRouter>
            <Routes>
                {/* Define routes and map them to components */}
                {/* Route for the home page */}
                <Route path="/" element={<EnveseurApp />} />
                {/* Route for the cookie policy page */}
                <Route path="/cookie-policy/" element={<CookiePolicy />} />
                {/* Route for the privacy policy page */}
                <Route path="/privacy-policy/" element={<PrivacyPolicy />} />
                {/* Route for the legal note page */}
                <Route path="/legal-note/" element={<LegalNote />} />
                {/* Route for handling unknown paths (404 Not Found) */}
                <Route path="*" element={<Page404 />} />
                
            </Routes>
        </BrowserRouter>
    </>
)

// Export the App component as the default export
export default App